import { useState } from 'react'
import moment from 'moment'

const AddTaskManualy = ({ onClickAddTaskManualy, isDisabled }) => {
  const [date, setDate] = useState(moment().format('yyyy-MM-DD'))
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')

  const onSubmitForm = (e) => {
    e.preventDefault()

    if (minutes >= 60 || date === '') return

    const timeInSeconds = hours * 3600 + (minutes ? minutes * 60 : 0)
    const theDate = new Date(date)

    onClickAddTaskManualy(theDate, timeInSeconds)
  }

  return (
    <div className='add-task-manualy' style={{ marginTop: '20px' }}>
      <form onSubmit={onSubmitForm}>
        <div className='inputs-wrapper'>
          <div className='input-control'>
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type='date'
              max={moment().format('yyyy-MM-DD')}
            />
          </div>
          <div className='input-control'>
            <input
              value={hours}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 23)
                  setHours(e.target.value)
              }}
              type='number'
              maxLength='2'
              min='0'
              max='23'
              placeholder='hh'
            />
          </div>
          <div className='input-control'>
            <input
              value={minutes}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 59)
                  setMinutes(e.target.value)
              }}
              type='number'
              maxLength='2'
              min='0'
              max='59'
              placeholder='mm'
            />
          </div>
          <button
            disabled={
              isDisabled || date === '' || (minutes === '' && hours === '')
            }
            type='submit'
            className='button'
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddTaskManualy
