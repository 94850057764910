import { useEffect, useState } from 'react';
import Select from 'react-select';

const Dropdown = ({
  title,
  list,
  placeholder,
  onChange,
  selectedValue,
  isDisabled,
  test,
}) => {
  const [options, setOptions] = useState(
    list.map((item) => ({ value: item._id, label: item.name }))
  );

  useEffect(() => {
    setOptions(list.map((item) => ({ value: item._id, label: item.name })));
  }, [list]);

  useEffect(() => {
    setOptions(list.map((item) => ({ value: item._id, label: item.name })));
  }, [selectedValue]);

  return (
    <div className="dropdown">
      <Select
        name={title}
        id={title}
        value={
          (selectedValue &&
            options.find((option) => option.value === selectedValue._id)) ||
          ''
        }
        onChange={(option) => {
          onChange(option.value);
        }}
        isDisabled={isDisabled}
        placeholder={placeholder}
        options={options}
      />
    </div>
  );
};

export default Dropdown;
