import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_ADDRESS;

const axiosInstance = axios.create({
  baseURL,
});

export const get = async (url, queryParams = null, token = null) => {
  try {
    const { data } = await axiosInstance.get(url, {
      params: queryParams,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data ?? null;
  } catch (err) {
    throw new Error(err);
  }
};

export const post = async (url, reqData, queryParams = null, token = null) => {
  try {
    const { data } = await axiosInstance.post(url, reqData, {
      params: queryParams,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data ?? null;
  } catch (err) {
    throw new Error(err.response.data);
  }
};

export const patch = async (url, reqData, queryParams = null, token = null) => {
  try {
    const { data } = await axiosInstance.patch(url, reqData, {
      queryParams,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data ?? null;
  } catch (err) {
    throw new Error(err.response.data);
  }
};
