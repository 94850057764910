import { useContext, useState } from 'react'
import { LoginContext } from './context/LoginContext'
import TimeTracking from './components/TimeTracking'
import LoginForm from './components/LoginForm'
import Header from './components/Header'
import Report from './components/Report'

// const App = () => {
//   const { userData } = useContext(LoginContext)
//   const [activeTab, setActiveTab] = useState('tracker')

//   return (
//     <div className='app'>
//       <Header activeTab={activeTab} setActiveTab={setActiveTab} />
//       {!userData.user ? <LoginForm /> : <TimeTracking />}
//     </div>
//   )
// }
const App = () => {
  const { userData } = useContext(LoginContext)
  const [activeTab, setActiveTab] = useState('tracker')

  return (
    <div className='app'>
      <Header activeTab={activeTab} setActiveTab={setActiveTab} />
      {!userData.user ? (
        <LoginForm />
      ) : activeTab === 'tracker' ? (
        <TimeTracking />
      ) : (
        <Report />
      )}
    </div>
  )
}

export default App
