import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import success from '../assets/success.svg';
import failed from '../assets/failed.svg';

const ConfirmTime = ({
  timeInSeconds,
  setIsConfirmTimeOpen,
  setIsSubmiting,
  saveTask,
  isLoading,
  isSuccess,
  isEditMode = false,
}) => {
  const [hours, setHours] = useState(Math.floor(timeInSeconds / 3600));
  const [minutes, setMinutes] = useState(Math.floor((timeInSeconds / 60) % 60));
  // const [seconds, setSeconds] = useState(timeInSeconds % 60);

  const onSubmitSaveTimeTracked = (e) => {
    e.preventDefault();

    if (!isEditMode) {
      setIsConfirmTimeOpen(false);
      setIsSubmiting(true);
    }

    const res = hours * 3600 + minutes * 60;
    saveTask(res);
  };

  return (
    <div className='add-task-manualy'>
      <form onSubmit={onSubmitSaveTimeTracked}>
        <div className='inputs-wrapper'>
          {!isEditMode && <div className='task-tracked'>Task tracked:</div>}
          <div className='input-control confirm-task'>
            <label htmlFor='hours'>hours</label>
            <input
              value={hours}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 23)
                  setHours(e.target.value);
              }}
              id='hours'
              type='number'
              maxLength='2'
              min='0'
              max='23'
              placeholder='hh'
            />
          </div>
          <div className='input-control confirm-task'>
            <label htmlFor='minutes'>minutes</label>
            <input
              value={minutes}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 59)
                  setMinutes(e.target.value);
              }}
              id='minutes'
              type='number'
              maxLength='2'
              min='0'
              max='59'
              placeholder='mm'
            />
          </div>
          {/* <div className='input-control'>
            <input
              value={seconds}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 59)
                  setSeconds(e.target.value);
              }}
              type='number'
              maxLength='2'
              min='0'
              max='59'
              placeholder='ss'
            />
          </div> */}
          {isEditMode ? (
            <>
              {isLoading ? (
                <div className='button'>
                  <ClipLoader color={'white'} loading={true} size={30} />
                </div>
              ) : (
                isSuccess === undefined && (
                  <button type='submit' className='button'>
                    Save
                  </button>
                )
              )}
              {isSuccess !== undefined && (
                <div className='button'>
                  {isSuccess ? (
                    <img src={success} alt='success' />
                  ) : (
                    <img src={failed} alt='failed' />
                  )}
                </div>
              )}
            </>
          ) : (
            <button type='submit' className='button'>
              Save
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ConfirmTime;
