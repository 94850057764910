import validtor from 'validator';
import { useContext, useState } from 'react';
import { saveUserOnCookie } from '../cookies/cookies';
import { logInAction } from '../actions/loginActions';
import { LoginContext } from '../context/LoginContext';
import { login } from '../server/user';

const LoginForm = () => {
  const { dispatchUserData } = useContext(LoginContext);

  const [email, setEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const validateEmail = () => email.length > 0 && validtor.isEmail(email);
  const validatePassword = () => password.length > 0;

  const onSubmitForm = async (event) => {
    event.preventDefault();

    if (!validateEmail()) {
      setEmailErrorMessage('Please enter a valid email');
    }
    if (!validatePassword()) {
      setPasswordErrorMessage('Please enter a valid password');
    }

    if (validateEmail() && validatePassword()) {
      try {
        const userData = await login(email, password);
        dispatchUserData(logInAction(userData));
        saveUserOnCookie(userData);
      } catch (err) {
        const newError = err.toString().replace('Error: ', '');
        if (newError === 'Wrong password') {
          setPasswordErrorMessage(newError);
        } else {
          setEmailErrorMessage(newError);
        }
      }
    }
  };

  return (
    <div className='login-form'>
      <form onSubmit={onSubmitForm}>
        <div className='form-group'>
          <div className='input-control'>
            <input
              onChange={(e) => {
                setEmail(e.target.value.trim());
                setEmailErrorMessage('');
              }}
              value={email}
              className={emailErrorMessage ? 'error' : ''}
              type='text'
              id='email'
              placeholder='Email'
            />
          </div>

          {emailErrorMessage && (
            <p className='error-message'>{emailErrorMessage}</p>
          )}
        </div>

        <div className='form-group'>
          <div className='input-control'>
            <input
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordErrorMessage('');
              }}
              value={password}
              className={passwordErrorMessage ? 'error' : ''}
              type='password'
              id='password'
              placeholder='Password'
            />
          </div>

          {passwordErrorMessage && (
            <p className='error-message'>{passwordErrorMessage}</p>
          )}
        </div>

        <button
          className='button'
          type='submit'
          disabled={emailErrorMessage || passwordErrorMessage}
        >
          <p className='btn-text'>Let’s get some job done!</p>
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
