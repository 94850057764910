import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Loader from './Loader';
import { getUnfulfilledDates, getUserTasks } from '../server/task';
import { LoginContext } from '../context/LoginContext';
import ReportTable from './ReportTable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Report = () => {
  const { userData } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(moment().format('yyyy-MM-DD'));
  const [tasks, setTasks] = useState([]);
  const [unfulfilledDates, setUnfullfilledDates] = useState([]);

  const onChangeSetDate = (date) => {
    const formattedDate = moment(date).format("yyyy-MM-DD");
    setIsLoading(true);
    setDate(formattedDate);

    getUserTasks(formattedDate, userData.token)
      .then((tasksData) => {
        setTasks(tasksData);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    getUnfulfilledDates(userData.token)
    .then((unfulfilledDates) => {
      const dates = unfulfilledDates.map((date) => {
        return new Date(date)
      })
      setUnfullfilledDates(dates)
    })
    .catch((err) => console.log(err))
    

    getUserTasks(date, userData.token)
      .then((tasksData) => {
        setTasks(tasksData);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className='report'>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='report-wrapper'>
          <div className='input-control'>
            <DatePicker 
              highlightDates={unfulfilledDates}
              value={date}
              onChange={onChangeSetDate}
              maxDate={new Date()}
            />
            <input
              style={{pointerEvents: 'none', position: 'absolute'}}
              value={date}
              type='date'
            />
          </div>
          {tasks.length > 0 ? (
            <ReportTable tasks={tasks} />
          ) : (
            <div>No Tasks</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Report;
