import { useContext, useState } from 'react'
import { LoginContext } from '../context/LoginContext'
import womanLaptop from '../assets/womanLaptop.png'
import logo from '../assets/logo.png'
import threeDots from '../assets/threeDots.svg'
import Menu from './Menu'
import TabsBar from './TabsBar'

const Header = ({ activeTab, setActiveTab }) => {
  const { userData } = useContext(LoginContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onClickToggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      {isMenuOpen && (
        <div
          onClick={() => setIsMenuOpen(false)}
          className='blur-background'
        ></div>
      )}
      <div className={`header ${userData.user ? 'logged-in' : ''}`}>
        {!userData.user && (
          <img src={womanLaptop} className='womanLaptop' alt='womanLaptop' />
        )}
        <img src={logo} alt='logo' className={!!userData.user ? 'left' : ''} />
        {!!userData.user && (
          <img
            className='threeDots'
            onClick={onClickToggleIsMenuOpen}
            src={threeDots}
            alt='threeDots'
          />
        )}
        {isMenuOpen && <Menu />}
        {userData.user && (
          <TabsBar activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
      </div>
    </>
  )
}

export default Header
