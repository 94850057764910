import { useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import play from '../assets/play.svg';
import stop from '../assets/stop.svg';
import success from '../assets/success.svg';
import failed from '../assets/failed.svg';

const Timer = ({
  seconds,
  setSeconds,
  isRunning,
  onClickStartTimer,
  isDisabled,
  isSubmiting,
  isSuccess,
}) => {
  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (!isRunning) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, seconds, setSeconds]);

  return (
    <div className={`timer ${seconds !== 0 ? 'redBg' : ''}`}>
      {isSubmiting ? (
        <div className='submiting'>
          {isSuccess !== undefined && isSuccess ? (
            <img src={success} alt='success' />
          ) : isSuccess !== undefined && !isSuccess ? (
            <img src={failed} alt='failed' />
          ) : (
            <ClipLoader color={'white'} loading={true} size={30} />
          )}
          <span>{isSuccess === undefined ? 'Submiting' : 'Submited'}</span>
        </div>
      ) : (
        <>
          <div className='buttons'>
            {seconds === 0 ? (
              <button
                type='button'
                onClick={onClickStartTimer}
                disabled={isDisabled}
              >
                <img src={play} /> Click to start
              </button>
            ) : (
              <button type='submit'>
                <img src={stop} className='stop' /> Click to stop
              </button>
            )}
          </div>
          <div className='digits'>
            <span>
              {('0' + Math.floor((seconds / 60 / 60) % 60)).slice(-2)}:
            </span>
            <span>{('0' + Math.floor((seconds / 60) % 60)).slice(-2)}:</span>
            <span>{('0' + (seconds % 60)).slice(-2)}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default Timer;
