const USER_DATA = 'user-data'

export const saveUserOnCookie = (userData) => {
  const jsonUserData = JSON.stringify(userData)
  localStorage.setItem(USER_DATA, jsonUserData)
}

export const deleteUserOnCookie = () =>
  localStorage.removeItem(USER_DATA)

export const getUserFromCookie = () => {
  const jsonUserData = localStorage.getItem(USER_DATA)
  if (jsonUserData === undefined) return null

  return JSON.parse(jsonUserData)
}
