export const intialUserDataState = { user: null, token: '', isAdmin: null };

const logInReducer = (userData, action) => {
  switch (action.type) {
    case 'LOG-IN':
      const userObj = {
        user: { ...action.user },
        token: action.token,
        isAdmin: action.isAdmin
      };
      return userObj;
    case 'LOG-OUT':
      return intialUserDataState;
    default:
      return { ...userData };
  }
};

export default logInReducer;
