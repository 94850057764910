import { useContext, useState } from 'react'
import moment from 'moment'
import { ClipLoader } from 'react-spinners'
import { LoginContext } from '../context/LoginContext'
import { newDayOff } from '../server/dayOff'
import success from '../assets/success.svg'
import failed from '../assets/failed.svg'
import { DateRangePicker } from 'react-date-range';

const getDaysArray = (start, end) => {
  for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
  }
  return arr;
};

const Menu = () => {
  const { userData } = useContext(LoginContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(undefined)

  const [isSickVacationOpen, setIsSickVacationOpen] = useState(false)
  const [date, setDate] = useState(moment().format('yyyy-MM-DD'))
  const [type, setType] = useState('sick')
  const [halfDay, setHalfDay] = useState(false)
  const [selectionRange, setSelectionRange] =  useState({
    startDate: new Date(),
    endDate: new Date(),  
    key: 'selection',
  }) 

  const onSubmitSickVacation = (e) => {
    e.preventDefault()
    setIsLoading(true)

    let selectedRangeDate = {...selectionRange};
    delete selectedRangeDate.key;
    
    if(selectedRangeDate.endDate === selectedRangeDate.startDate) {
      selectedRangeDate = selectedRangeDate.startDate;
    }
    
    if(selectedRangeDate?.startDate && selectedRangeDate?.endDate) {
      selectedRangeDate = getDaysArray(selectedRangeDate.startDate, selectedRangeDate.endDate);
    }

    const data = {
      user: userData.user._id,
      type,
      halfDay,
      date: halfDay ? date : selectedRangeDate,
    }

    newDayOff(data, userData.token)
      .then(() => setIsSuccess(true))
      .catch(() => setIsSuccess(false))
      .finally(() => {
        setIsLoading(false)

        setTimeout(() => {
          setIsSuccess(undefined)
          setDate(moment().format('yyyy-MM-DD'))
          setSelectionRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          })
          setType('sick')
          setHalfDay(false)
        }, 1500)
      })
  }

  function handleSelect(ranges){
    setSelectionRange(ranges?.selection)
  }

  return (
    <div className='menu'>
      <h2>MENU</h2>
      <span className='version'>v 1.0.5</span>
      <hr />
      <div className='menu-item'>
        <p
          className={isSickVacationOpen ? 'bold' : ''}
          onClick={() => setIsSickVacationOpen(!isSickVacationOpen)}
        >
          Day off report
        </p>
        {isSickVacationOpen && (
          <form onSubmit={onSubmitSickVacation}>
            <div className='inputs-wrapper'>
              <div className='input-control'>
                <input
                  onChange={() => setType('sick')}
                  checked={type === 'sick'}
                  type='radio'
                  id='sick'
                  name='type'
                />
                <label htmlFor='sick'>sick</label>
                <input
                  onChange={() => setType('vacation')}
                  checked={type === 'vacation'}
                  type='radio'
                  id='vacation'
                  name='type'
                />
                <label htmlFor='vacation'>vacation</label>
                <input
                  onChange={() => setType('reserveDuty')}
                  checked={type === 'reserveDuty'}
                  type='radio'
                  id='reserveDuty'
                  name='type'
                />
                <label id='reserveDuty-label' htmlFor='reserveDuty'>
                  reserve duty
                </label>
              </div>
              <div className='input-control'>
                <input
                  onChange={() => setHalfDay(!halfDay)}
                  checked={halfDay}
                  type='checkbox'
                  id='half-day'
                />
                <label htmlFor='half-day'>half day</label>
              </div>
              <div className='input-control'>
                {
                  halfDay ? 
                   <input
                    type='date'
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                  />
                  :
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    className='calendar-wrapper'
                  />
                }
              </div>
            </div>
            {isLoading ? (
              <div className='button'>
                <ClipLoader color={'white'} loading={true} size={30} />
              </div>
            ) : (
              isSuccess === undefined && (
                <button type='submit' className='button'>
                  Save
                </button>
              )
            )}
            {isSuccess !== undefined && (
              <div className='button'>
                {isSuccess ? (
                  <img src={success} alt='success' />
                ) : (
                  <img src={failed} alt='failed' />
                )}
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  )
}

export default Menu
