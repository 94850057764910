import { useContext, useState } from 'react';
// import downArrow from '../assets/downArrow.svg';
import pencil from '../assets/pencil.svg';
import { LoginContext } from '../context/LoginContext';
import { updateTask } from '../server/task';
import ConfirmTime from './ConfirmTime';

const parseSecondsToTime = (seconds) => {
  let res = `${('0' + Math.floor(seconds / 3600)).slice(
    Math.floor(seconds / 3600) < 100
      ? -2
      : Math.floor(seconds / 3600) < 1000
      ? -3
      : -4
  )}:${('0' + Math.floor((seconds / 60) % 60)).slice(-2)}`;

  return res[0] === '0' ? res.substring(1) : res;
};

export const tasksSum = (tasks) =>
  tasks?.length
    ? tasks.reduce((a, b) => ({
        timeInSeconds: a.timeInSeconds + b.timeInSeconds,
      })).timeInSeconds
    : 0;

const ReportTable = ({ tasks }) => {
  const [tasksState, setTasksState] = useState(tasks);

  return (
    <div className='report-table'>
      <div className='table-body'>
        {tasksState.map((task) => (
          <Row
            key={task._id}
            task={task}
            tasksState={tasksState}
            setTasksState={setTasksState}
          />
        ))}
      </div>
      <div className='total'>
        <div>Total</div>
        <div>{parseSecondsToTime(tasksSum(tasksState))}</div>
      </div>
    </div>
  );
};

const Row = ({ task, tasksState, setTasksState }) => {
  const { userData } = useContext(LoginContext);
  const [isConfirmTimeOpen, setIsConfirmTimeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(undefined);

  const saveTask = (res) => {
    setIsLoading(true);

    updateTask(task._id, res + (task.timeInSeconds % 60), userData.token)
      .then((taskData) => {
        const tasks = [...tasksState];
        const taskIndex = tasksState.findIndex((el) => el._id === taskData._id);
        tasks[taskIndex] = taskData;

        setTasksState(tasks);
        setIsSuccess(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsSuccess(false);
        setIsLoading(false);
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsConfirmTimeOpen(false);
          setIsSuccess(undefined);
        }, 1000);
      });
  };

  return (
    <div className={`table-row ${isConfirmTimeOpen ? 'edit-mode' : ''}`}>
      <div className='company'>
        {task.company.name} - {task.project.name}
      </div>
      {isConfirmTimeOpen ? (
        <ConfirmTime
          timeInSeconds={task.timeInSeconds}
          saveTask={saveTask}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isEditMode={true}
        />
      ) : (
        <div className='time'>{parseSecondsToTime(task.timeInSeconds)}</div>
      )}
      <div>{task.assignmentType}</div>
      <img
        onClick={() => setIsConfirmTimeOpen(!isConfirmTimeOpen)}
        className='pencil'
        src={pencil}
        alt='pencil'
      />
    </div>
  );
};
// const Row = ({ task }) => {
//   const [isTaskDescriptionOpen, setIsTaskDescriptionOpen] = useState(false);

//   return (
//     <div
//       onClick={() => setIsTaskDescriptionOpen(!isTaskDescriptionOpen)}
//       className='table-row'
//     >
//       <div className='company'>{task.company.name}</div>
//       <div className='time'>{parseSecondsToTime(task.timeInSeconds)}</div>
//       <div>{task.assignmentType}</div>
//       {/* {task.taskDescription !== '' && (
//         <img
//           className={`down-arrow ${isTaskDescriptionOpen ? 'up-arrow' : ''}`}
//           src={downArrow}
//           alt='down-arrow'
//         />
//         )}
//         {isTaskDescriptionOpen && (
//             <div className='task-description'>{task.taskDescription}</div>
//         )} */}
//       <img className='pencil' src={pencil} alt='pencil' />
//     </div>
//   );
// };

export default ReportTable;
