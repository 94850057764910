const TabsBar = ({ activeTab, setActiveTab }) => {
  return (
    <div className='tabs-bar'>
      <div
        className={activeTab === 'tracker' ? 'active' : ''}
        onClick={() => setActiveTab('tracker')}
      >
        TRACKER
      </div>
      <div
        className={activeTab === 'report' ? 'active' : ''}
        onClick={() => setActiveTab('report')}
      >
        REPORT
      </div>
    </div>
  );
};

export default TabsBar;
